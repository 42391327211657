

const ViewContainer = ({ JSONdata }) => {
    const handleLinkClick = (link) => {
        window.open(link, "_blank"); // Opens the link in a new tab
    };

    return (
        <div className='px-20'>
            {JSONdata.map((item, index) => (
                <div
                    key={index}
                    className="mb-5 border border-gray-300 p-4 rounded-lg bg-gray-100"
                >
                    <div
                        onClick={() => handleLinkClick(item.link)}
                        className="cursor-pointer"
                    >
                        <h1 className="text-2xl text-blue-700 hover:underline hover:text-blue-700">{item.title}</h1>
                    </div>
                    {item.snippet && (
                        <>
                            <p style={{ fontSize: "14px", color: "#666" }}>
                                {item.snippet.includes(" ... ")
                                    ? item.snippet.split(" ... ")[1]?.replace(/^\.\.\. /, '') || ''
                                    : item.snippet.replace(/^\.\.\. /, '') || ''}
                            </p>
                            <p className="text-gray-900">
                                {item.snippet.split(" ... ")[0] || ''}
                            </p>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ViewContainer;

import { useState } from 'react'
import Search from './components/search'
import axios from "axios";
import { API_URL } from "./cred";
import ViewContainer from './components/ViewContainer';

const App = () => {
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState([]);

  const handleSearch = async () => {
    setData([])
    const formData = {
      q: searchText,
    };

    try {
      const response = await axios.post(`${API_URL}/googleCustomSearch`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setData(response.data)

      if (response.status === 200) {
        console.log("value return: ", response.data)
      } else {
        console.error('Error searching database');
      }
    } catch (error) {
      console.error('Error searching database:', error);
    }
  };
  return (
    <>
      <Search searchText={searchText} setSearchText={setSearchText} handleSearch={handleSearch} />
      <ViewContainer JSONdata={data} />
    </>
  )
}

export default App
const Search = ({ searchText, setSearchText, handleSearch }) => {
    return (
      <div className="flex flex-nowrap bg-gray-300 mb-2 mx-20">
        <form 
          className="flex flex-nowrap max-w-lg pl-20 my-5" 
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission
            handleSearch();
          }}
        >
          <div className="relative w-full">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            </div>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-blue-500 block w-[400px] focus:outline-none focus:ring-blue-300 focus:px-1 ps-2 p-2"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="flex flex-nowrap items-center py-2.5 px-3 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4"
          >
            <svg
              className="w-4 h-4 me-2"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            Search
          </button>
        </form>
      </div>
    );
  };
  
  export default Search;
  